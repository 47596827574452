import React from "react";

import CardPage from "../../../Layout/CardPage";
import Form from "ae-forms";
import StyledText from "ae-base/Base/StyledText";
import ScheduleOnOff from "../../../../schemas/ScheduleOnOff";
import { useIdPrefix, useResources } from "@aetonix/hooks";

var DEFAULT_SETTINGS = {
	time: {
		hours: "0",
		minutes: "0",
	},
	action: "offline",
	day: 0,
	months: new Date().toJSON(),
};

export default function AddSchedule(props){
	const { localize, dispatch } = useResources();
	const idPrefix = useIdPrefix();

	var screen = props.screen;

	var createSchedule = dispatch("schedulemaker:trigger:add");
	var goBack = () => {
		const modifySchedule = dispatch("schedules:trigger:modify");
		const sendEvent = dispatch("usermetrics:trigger:track", "cancel add new status schedule to self");
		modifySchedule();
		sendEvent();
	};
	var discardchanges = localize("addschedule.discardchanges");
	var scheduleTitle = localize("addschedule.scheduleTitle");

	return (
		<CardPage screen={screen} online={props.online}>
			<StyledText bold large>{scheduleTitle}</StyledText>
			<Form id_prefix={idPrefix + "addSchedule"} schema={ScheduleOnOff} defaultData={DEFAULT_SETTINGS} onSubmit={createSchedule} onCancel={goBack} cancelText={discardchanges} />
		</CardPage>
	);
}
