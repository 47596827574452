import React from "react";
import {
	StyleSheet,
	Platform,
	View,
} from "react-native";

import Form from "ae-forms";
import StyledText from "ae-base/Base/StyledText";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import OxygenSchema from "../../../../schemas/BloodOxygen";
import BloodPressureSchema from "../../../../schemas/BloodPressure";
import WeightImperialSchema from "../../../../schemas/WeightImperial";
import WeightMetricsSchema from "../../../../schemas/WeightMetrics";
import BodyTemperatureMetricsSchema from "../../../../schemas/BodyTemperatureMetrics";
import BodyTemperatureImperialSchema from "../../../../schemas/BodyTemperatureImperial";
import BloodSugarImperialSchema from "../../../../schemas/BloodSugarImperial";
import BloodSugarMetricsSchema from "../../../../schemas/BloodSugarMetrics";
import ActivitySchema from "../../../../schemas/Activity";
import Modal from "../../../Layout/Modal";
import Card from "../../../Layout/Card";
import Heading from "ae-base/Base/Heading";
import Style from "ae-style";

import format from "string-template";
import { useMeasureUnits } from "@aetonix/hooks";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	card: {
		flexShrink: 1,
		flexWrap: "wrap",
	},
	buttonView: {
		flexDirection: "row",
		justifyContent: "center",
		marginTop: Style.layout.marginBordered,
	},
	container: {
		...Platform.select({
			ios: {},
			android: {},
			default: {
				flexShrink: 1,
				flexWrap: "wrap",
			},
		}),
	},
});

var SCHEMA_MAP = {
	"bloodpressure": BloodPressureSchema,
	"bloodoxygen": OxygenSchema,
	"bloodsugar": {
		"mg": BloodSugarImperialSchema,
		"mmol": BloodSugarMetricsSchema,
	},
	"bodytemperature": {
		"fahrenheit": BodyTemperatureImperialSchema,
		"celsius": BodyTemperatureMetricsSchema,
	},
	"weight": {
		"lbs": WeightImperialSchema,
		"kg": WeightMetricsSchema,
	},
	"activity": ActivitySchema,
};

var UNIT_MAP = {
	"bloodpressure": "",
	"bloodsugar": "mmol/L",
	"bodytemperature": "C",
	"bloodoxygen": " ",
	"weight": "kg",
	"activity": "",
	"customindicator": "",
};

export default function NewReadingModal(props) {
	const {
		localize,
		dispatch,
		localizeFrom,
	} = useResources();
	const measureUnits = useMeasureUnits();


	var model = props.model;
	const page = props.page || model.page;
	let section = page.replace("managed", "").replace("my", "");

	const onHealthIndicatorPage = Object.keys(UNIT_MAP).some(healthIndicatorPage => page.indexOf(healthIndicatorPage) !== -1);
	const onWorkflowReadFromDevice = model.workflowaction && model.myhealthdevice;
	const openHealthIndicatorNewReading = onHealthIndicatorPage || onWorkflowReadFromDevice;

	if (!openHealthIndicatorNewReading) return null;
	if (onWorkflowReadFromDevice) section = model.myhealthdevice;

	const onAtouchAway = props.newreading;
	const newreading = onAtouchAway ? onAtouchAway : model.myhealth.newreading;
	const workflowaction = model.workflowaction;
	let managed = false;

	if (page.indexOf("managed") !== -1) {
		model = model.manage;
		managed = true;
	} else if (page.indexOf("my") !== -1)
		model = model.myhealth;


	let currentViewingCustomIndicator = null;
	if (managed) {
		const managedCustomIndicatorsList = model.managedcustomindicatorslist;
		const managedCustomIndicator = model.managedcustomindicator;
		currentViewingCustomIndicator = managedCustomIndicatorsList?.find(indicator => indicator._id === managedCustomIndicator.indicatorId) || {};
	} else {
		const customIndicatorsList = model.customindicatorslist;
		const customIndicator = model.customindicator;
		currentViewingCustomIndicator = customIndicatorsList?.find(indicator => indicator._id === customIndicator.indicatorId) || {};
	}

	var settings = model.settings || {};

	var openNewReading = newreading.open ? newreading.open : null;

	var confirmReading = newreading.confirm ? newreading.confirm : null;
	var closeAction = dispatch("newreading:trigger:hide");
	var closeConfirm = dispatch("newreading:trigger:deny");
	var submitReading = dispatch(section + ":trigger:record");
	var savebeforeconfirm = dispatch(section + ":trigger:savebeforeconfirm");
	var confirmText = localize("glucoseedit.save");
	var closeButton = localize("glucoseedit.cancel");
	var unit = UNIT_MAP[section];
	var tempUnits = localize("bodytemperature." + measureUnits.temperatureUnits);
	var glucoseUnits = localize("bloodsugar." + measureUnits.glucometerUnits);

	if(section === "weight")
		unit = measureUnits.weightUnits;

	if(section === "bodytemperature")
		unit = tempUnits;

	if(section === "bloodsugar")
		unit = glucoseUnits;

	if (section === "customindicator") unit = currentViewingCustomIndicator?.unit || "";

	var reading = "";
	var latestReading = (model[section] || {}).latestreading;
	var latestReadingX = (model[section] || {}).latestreadingx;
	var latestReadingY = (model[section] || {}).latestreadingy;

	if (managed) {
		latestReading = (model[`managed${section}`] || {}).latestreading;
		latestReadingX = (model[`managed${section}`] || {}).latestreadingx;
		latestReadingY = (model[`managed${section}`] || {}).latestreadingy;
		submitReading = dispatch("managed" + section + ":trigger:record");
		savebeforeconfirm = dispatch("managed" + section + ":trigger:savebeforeconfirm");
	}

	if (onWorkflowReadFromDevice) {
		if (!onAtouchAway) {
			latestReading = (model.myhealth[section] || {}).latestreading;
			latestReadingX = (model.myhealth[section] || {}).latestreadingx;
			latestReadingY = (model.myhealth[section] || {}).latestreadingy;
		}
		submitReading = dispatch(section + ":trigger:recordtodevicereading");
	}

	if ((latestReading !== undefined && latestReading !== null))
		if(section === "bloodpressure")
			reading = format("{latest} / {latestx} mmol/hg - {latesty} bpm", {
				latest: latestReading,
				latestx: latestReadingX,
				latesty: latestReadingY,
			});
		else if (section === "bloodsugar")
			reading = format("{latest}", {
				latest: latestReading,
			});
		else if (section === "bodytemperature")
			reading = format("{latest}", {
				latest: latestReading,
			});
		else if (section === "bloodoxygen")
			reading = format("{latest}% - {latestx}bpm", {
				latest: latestReading,
				latestx: latestReadingX,
			});
		else if (section === "weight")
			reading = format("{latest}", {
				latest: latestReading,
			});
		else if (section === "activity")
			reading = format("{latest}", {
				latest: latestReading,
			});
		else if (section === "customindicator")
			reading = format("{latest}", {
				latest: latestReading,
			});



	var schema = SCHEMA_MAP[section];

	if(section === "weight")
		schema = schema[measureUnits.weightUnits];
	else if(section === "bodytemperature")
		schema = schema[measureUnits.temperatureUnits];
	else if(section === "bloodsugar")
		schema = schema[measureUnits.glucometerUnits];

	if (section === "customindicator") {
		const indicatorLocalization = currentViewingCustomIndicator.localization;
		const indicatorUnit = currentViewingCustomIndicator.unit;
		const indicatorTitle = localizeFrom(indicatorLocalization, ".") || localize("myhealth.indicator");

		schema = {
			title: indicatorTitle,
			description: "glucose.manual_entry",
			type: "object",
			properties: {},
		};

		schema.properties[unit] = {
			type: "number",
			description: `${indicatorTitle} (${indicatorUnit})`,
			placeholder: `${indicatorTitle} (${indicatorUnit})`,
		};
		schema.properties.comment = {
			description: "bodytemperature.commenttitle",
			type: "string",
			multiline: true,
			placeholder: "bodytemperature.comment",
		};
		schema.required = [unit];
	}

	var title = localize(schema.description) || localize(section + ".title");

	let workflowSchema = {};
	if (workflowaction)	{
		const {properties, ...rest} = schema;
		workflowSchema = {"properties": {}, ...rest};
		for (let property in schema.properties)
			if (property !== "comment")
				workflowSchema.properties[property] = schema.properties[property];


	}

	var formDefaults = {
		weightUnits: measureUnits.weightUnits,
		tempUnits: measureUnits.temperatureUnits,
		glucoseUnits: measureUnits.glucometerUnits,
		followup: settings["post" + section],
	};

	var confirmChange = confirmReading  ? (
		<ConfirmationModal
			open={confirmReading}
			title={localize("glucoseeditconfirm.title")}
			titleIcon="warning"
			confirmText={localize("glucoseeditconfirm.confirm")}
			cancelText={localize("glucoseeditconfirm.deny")}
			online={model.online}
			onConfirm={submitReading}
			onCancel={closeConfirm}>
			<StyledText size={Style.text.emphasized} bold contrast centered>{localize("glucoseeditconfirm.detail") + reading + " " +  unit}
			</StyledText>
		</ConfirmationModal>
	) : (
		null
	);

	return (
		<Modal open={openNewReading} online={props.model.online}>
			<Card scroll atouchaway={props.atouchaway} style={styles.card}>
				<View style={styles.container}>
					<Heading title={title} />
					<Form schema={workflowaction ? workflowSchema : schema}
						submitText={confirmText}
						onSubmit={savebeforeconfirm}
						submitStyle={styles.buttonView}
						onCancel={() => closeAction(true)}
						cancelText={closeButton}
						defaultData={formDefaults}
						bigger
					/>
				</View>
			</Card>
			{confirmChange}
		</Modal>
	);
}
