import React, {
	useContext,
} from "react";
import {
	View,
} from "react-native";

import NotificationIcon from "ae-base/Base/NotificationIcon";
import StyledButton from "ae-base/Base/StyledButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";
import StyledText from "ae-base/Base/StyledText";
import {
	StyleSheet,
	TouchableOpacity,
} from "react-native";
import MainContent from "../../Layout/MainContent";
import Divider from "../../Layout/Divider";
import ListAdd from "ae-base/List/ListAdd";
import ListItem from "ae-base/List/ListItem";

import CallGroup from "../../Groups/CallGroup";
import Contact from "../../Contacts/Contact";
import ContactHeading from "../../Contacts/ContactHeading";
import ContactHide from "../../Contacts/ContactHide";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import getVersion, { NewCallingVersion } from "../../../utils/GetVersion";
import MeetingContext from "../../../Calling/context/MeetingContext";
import useEnabledFeatures from "../../../hooks/useEnabledFeatures/useEnabledFeatures";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { createStyled } from "@emotion/primitives-core";
import { faVideo, faVideoSlash ,faTextHeight, faCommentAlt, faCommentSlash, faComment ,faPhoneSlash, faPhone} from "@fortawesome/free-solid-svg-icons";
import Style from "@aetonix/styles";
import { getUserCallRoles } from "../../../Calling/libraries/users.library";
import useSelf from "../../../hooks/useSelf/useSelf";
import sortNames from "../../../utils/SortNames";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		addBox: {
			marginLeft: 24,
		},
		badge: {
			position: "absolute",
			right: -Style.layout.badge / 2,
			top: -Style.layout.badge / 2,
		},
		inviteAction: {
			flexDirection: "column",
		},
		inviteIncoming: {
			backgroundColor: Style.look.contrast.background,
		},
		inviteOutgoing: {
			backgroundColor: Style.colors.tertlight,
		},
		groupInviteContainer: {
			marginLeft: Style.layout.marginContentSmall,
			flexDirection: "column",
			flex: 1,
		},
	},
	512: {
		addBox: {
			marginLeft: 30,
		},
		inviteAction: {
			flexDirection: "row",
		},
	},
});

const styled = createStyled(StyleSheet);

const CallButton = styled(TouchableOpacity)({
	paddingLeft:10,
});

const MessageButton = styled(TouchableOpacity)({
	paddingLeft:10,
});

export default function ContactList(props) {
	var list = props.list || [];
	var availability = props.availability;
	var hiding = props.hiding;
	var count = props.count;
	var displaying = props.displaying;
	var missed = props.missed;
	var incoming = props.incoming || [];
	var outgoing = props.outgoing || [];
	var callgroups = props.callgroups;
	var callgroupsMembers = props.callgroupsMembers;
	var contactsettings = props.contactsettings;
	let managedCount = props.manageCount;

	var screen = props.screen;

	var contacts = sortNames(list).map(function (contact) {
		var id = contact._id;
		var isAvailable = availability[id];
		var missedContact = missed[id];
		var setting = contactsettings[id];

		return (
			<OwnContact key={id} contact={contact} isAvailable={isAvailable} missed={missedContact} settings={setting} screen={screen} />
		);
	});

	var renderedCallGroups = callgroups.map(function (group) {
		var id = group._id;
		return (
			<CallGroup key={id} group={group} screen={screen} showAvailable members={callgroupsMembers} />
		);
	});

	var renderedIncoming = incoming.map(function (request) {
		return (
			<IncomingRequest key={request._id} request={request} screen={screen} />
		);
	});

	var renderedOutgoing = outgoing.map(function (request) {
		var id = request._id;

		return (
			<OutgoingRequest request={request} key={id} screen={screen} />
		);
	});

	var hideIndex = 0;
	var content = displaying ? []
		.concat(
			<Hide key={hideIndex++} hiding={hiding} />
		)
		.concat(
			<Divider key={hideIndex++} />
		)
		.concat(renderedIncoming)
		.concat(renderedCallGroups)
		.concat(contacts)
		.concat(renderedOutgoing)
		: null;

	return (
		<MainContent>
			<Heading count={count} displaying={displaying} screen={screen} />
			{content}
			<AddContact />
			<MassMessaging manageCount={managedCount}/>
		</MainContent>
	);
}

function Hide(props) {
	const {
		dispatch,
	} = useResources();
	var hiding = props.hiding;

	var toggleHide = dispatch("homepage:trigger:toggle:offline:contacts");

	return (
		<ContactHide id={"hideContact"} onPress={toggleHide} hiding={hiding} contrast />
	);
}

function Heading(props) {
	var count = props.count;
	var screen = props.screen;
	var displaying = props.displaying;

	var section = count ? "contacts" : "nocontacts";

	return (
		<ContactHeading
			count={count}
			displaying={displaying}
			section={section}
			screen={screen} />
	);
}

function AddContact(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var inviteanewcontact = localize("contactlist.inviteanewcontact");

	var openRequest = dispatch("desktop:trigger:page", {
		page: "request",
	});

	return (
		<ListAdd id={"add_contact"} style={styles.addBox} title={inviteanewcontact} onPress={openRequest} />
	);
}

function MassMessaging(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var inviteanewcontact = localize("bulkMessaging.createBulkMessage");

	var openRequest = dispatch("desktop:trigger:page", {
		page: "massmessage",
	});

	if(!props.manageCount)
		return null;

	return (
		<ListAdd id={"send_bulk_message"} style={styles.addBox} title={inviteanewcontact} onPress={openRequest} />
	);
}

function OwnContact(props) {
	const {
		dispatch,
		config,
	} = useResources();

	var screen = props.screen;

	var settings = props.settings || {};
	var contact = props.contact;
	var isAvailable = props.isAvailable;
	var missed = props.missed;
	var id = contact._id;

	const self = useSelf();
	const { isCallingEnabled } = useEnabledFeatures();

	var openProfile = dispatch("contactprofile:trigger:profile:open", {
		who: id,
		message: true,
	});

	var callContact = dispatch("calling:trigger:call", {
		who: id,
		reason: "screen",
	});

	var voiceCallContact = dispatch("calling:trigger:call", {
		who: id,
		reason: "screen",
		voiceOnly: true,
	});

	var openContactMessages = dispatch("messages:trigger:open", {
		from: id,
	});

	var renderMissedMessage = null;
	if (missed && missed.messages)
		renderMissedMessage = (
			<NotificationIcon style={styles.badge} title={missed.messages} />
		);

	var renderMissedVoiceCall = null;
	if (missed && missed.voiceCalls)
		renderMissedVoiceCall = (
			<NotificationIcon style={styles.badge} title={missed.voiceCalls} />
		);


	var renderMissedVideoCall = null;
	if (missed && missed.calls)
		renderMissedVideoCall = (
			<NotificationIcon style={styles.badge} title={missed.calls} />
		);

	var disableVoice = settings.voice || !isAvailable;
	var disableVideo = settings.video || !isAvailable;

	const version = getVersion(contact.versionInfo || {});
	const meetingContext = useContext(MeetingContext);
	let useOldCalling = true;

	const isNewCallingSupported = version && version.majorVersion >= NewCallingVersion;
	if (isNewCallingSupported && isCallingEnabled){
		const roles = getUserCallRoles(self, contact);
		callContact = () => meetingContext.onCreateMeeting(roles.recipient, `${config.lname}, ${config.fname}`, roles.participants, roles.administrators);
		useOldCalling = false;
	}

	return (
		<Contact contact={contact} inactive={!isAvailable} key={id} onPress={openProfile} showAvailable screen={screen} >
			<MessageButton testID={`${contact.fname}_${contact.lname}_messages`} onPress={openContactMessages}>
				<FontAwesomeIcon icon={faComment} color="#285E63" size={30} />
				{renderMissedMessage}
			</MessageButton>
			{useOldCalling && (<CallButton testID={`${contact.fname}_${contact.lname}_voicecall`} onPress={disableVoice ? null : voiceCallContact}>
				<FontAwesomeIcon icon={disableVoice ? faPhoneSlash : faPhone} color="#285E63" size={32} />
				{renderMissedVoiceCall}
			</CallButton>)}
			<CallButton testID={`${contact.fname}_${contact.lname}_videocall`} onPress={disableVideo ? null : callContact}>
				<FontAwesomeIcon icon={disableVideo ? faVideoSlash : faVideo} color="#285E63" size={32} />
				{renderMissedVideoCall}
			</CallButton>
		</Contact>
	);
}

function IncomingRequest(props){
	const { localize, dispatch } = useResources();
	var screen = props.screen;

	var accept = localize("contactlist.accept");
	var deny = localize("contactlist.deny");
	var contactrequest = localize("contactlist.contactrequest");
	var orggrouprequest = localize("contactlist.orggrouprequest");

	var managedrequest = localize("contactlist.managedrequest");

	var request = props.request;
	var from = request.from || {};
	var id = request._id;

	var acceptRequest = dispatch("requests:trigger:accept", {
		request: id,
		who: from?._id,
	});

	var denyRequest = dispatch("requests:trigger:deny", {
		request: id,
		who: from._id,
	});

	var acceptManagedRequest = dispatch("managementrequests:trigger:accept", {
		request: id,
	});

	var denyManagedRequest = dispatch("managementrequests:trigger:deny", {
		request: id,
	});

	var acceptOrgGroupRequest = dispatch("incomingorggrouprequests:trigger:accept", {
		request: id,
	});

	var denyOrgGroupRequest = dispatch("incomingorggrouprequests:trigger:deny", {
		request: id,
	});

	if (request.orgGroupInvite) {
		var orgGroupInviteTitle = orggrouprequest + request.orgGroup.name;
		if(request.from)
			return (
				<View style={styles.inviteIncoming} key={id}>
					<Contact title={orgGroupInviteTitle} margined contrast contact={request.from} key={id} screen={screen} >
						<View style={styles.inviteAction} >
							<StyledButton id={`${id}_orggroupinvite_accept`} title={accept} white onPress={acceptOrgGroupRequest} />
							<ButtonSpacer />
							<StyledButton id={`${id}_orggroupinvite_deny`} title={deny} alert onPress={denyOrgGroupRequest} />
						</View>
					</Contact>
				</View>
			);
		else
			return (
				<View style={styles.inviteIncoming} key={id}>
					<ListItem margined contrast>
						<View style={styles.groupInviteContainer}>
							<StyledText contrast small>{localize("groupinvite.title")}</StyledText>
							<StyledText contrast emphasized>{localize("groupinvite.invite", {name: request.orgGroup.name})}</StyledText>
						</View>
						<View style={styles.inviteAction} >
							<StyledButton id={`${id}_orggroupinvite_accept`} title={accept} white onPress={acceptOrgGroupRequest} />
							<ButtonSpacer />
							<StyledButton id={`${id}_orggroupinvite_deny`} title={deny} alert onPress={denyOrgGroupRequest} />
						</View>
					</ListItem>
				</View>
			);

	}

	if (request.contactInvite) return (
		<View style={styles.inviteIncoming} key={id}>
			<Contact title={contactrequest} margined contrast contact={request.from} key={id} screen={screen} >
				<View style={styles.inviteAction} >
					<StyledButton id={`${id}_contactinvite_accept`} title={accept} white onPress={acceptRequest} />
					<ButtonSpacer />
					<StyledButton id={`${id}_contactinvite_deny`} title={deny} alert onPress={denyRequest} />
				</View>
			</Contact>
		</View>
	);

	if (request.managedInvite) return (
		<View style={styles.inviteIncoming} key={id}>
			<Contact title={managedrequest} margined contrast contact={request.from} key={id} screen={screen} >
				<StyledButton id={`${id}_managedinvite_accept`}  title={accept} white onPress={acceptManagedRequest} />
				<ButtonSpacer />
				<StyledButton id={`${id}_managedinvite_deny`} title={deny} alert onPress={denyManagedRequest} />
			</Contact>
		</View>
	);

	return null;
}

function OutgoingRequest(props){
	const { localize, dispatch } = useResources();

	var screen = props.screen;

	var request = props.request;
	var id = request._id;
	var who = request?.to?._id;

	var cancel = localize("contactlist.cancel");
	var haveinvited = localize("contactlist.haveinvited");

	if (request.contactRequest) {
		var cancelRequest = dispatch("requests:trigger:cancel", {
			request: id,
			who: who,
		});

		return (
			<View style={styles.inviteOutgoing}>
				<Contact title={haveinvited} margined contact={request.to} key={id} screen={screen} >
					<StyledButton id={`${id}_contactinvite_outgoing_cancel`} title={cancel} onPress={cancelRequest} />
				</Contact>
			</View>
		);
	} else if (request.accountInvite) {
		var contact = {
			fname: request.email.replace("@", "\u200B@"),
			lname: "",
		};

		var cancelInvite = dispatch("accountinvites:trigger:cancel", {
			invite: id,
		});

		return (
			<View style={styles.inviteOutgoing}>
				<Contact title={haveinvited} margined contact={contact} key={id} screen={screen} >
					<StyledButton id={`${id}_accountinvite_outgoing_cancel`}  title={cancel} onPress={cancelInvite} />
				</Contact>
			</View>
		);
	} else
		return null;

}

