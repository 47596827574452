import React from "react";
import {
	Platform,
	View,
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import SquareButton from "ae-base/Base/SquareButton";
import StyledButton from "ae-base/Base/StyledButton";
import ManagedHeader from "../Pages/Managed/ManagedSection/ManagedHeader";
import MainScroll from "../Layout/MainScroll";
import VictoryGraph from "./VictoryGraph";
import ManagedPreceedingMeasure from "./ManagedPreceedingMeasure";
import ManagedComment from "./ManagedComment";
import { useMeasureUnits } from "@aetonix/hooks";
import { parseStatus } from "../../utils/ParseStatus";
import { LONG_LANGS } from "../../utils/LongLangs";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0:	{
		todaycontainer: {
			flexGrow: 1,
			justifyContent: "center",
			padding: Style.layout.paddingSlim,
			margin: Style.layout.marginSmall,
			marginBottom: Style.layout.marginMedium,
			flexDirection: "column",
		},
		dateLabel: {
			top: -6,
		},
		maincontainer: {
			flexDirection: "column",
		},
		row: {
			flexDirection: "row",
		},
		latest: {
			margin: Style.layout.marginSlim,
			width: Style.imageSize.quitesmall,
			height: Style.imageSize.quitesmall,
			justifyContent: "center",
			alignSelf: "center",
			borderColor: Style.colors.primary,
		},
		square: {
			borderStyle: "solid",
			borderRadius: Style.layout.marginSmall,
			borderWidth: Style.layout.border,
			backgroundColor: "white",
			alignSelf: "center",
			borderColor: Style.colors.primary,
			paddingVertical: Style.layout.paddingSmall,
		},
		multibuttons: {
			width: Style.layout.paddingContent,
			height: Style.layout.paddingContent,
		},
		padded: {
			alignSelf: "center",
		},
		multi: {
			flexGrow: 2,
			alignItems: "center",
			flexDirection: "column",
		},
		fiveday: {
			flexDirection: "row",
			marginBottom: Style.layout.marginLarge,
		},
		red: {
			borderColor: Style.colors.alert,
			borderWidth: 2,
		},
		green: {
			borderColor: Style.colors.primary,
		},
		greenStatus: {
			borderColor: Style.colors.green,
			borderWidth: 2,
		},
		yellow: {
			borderColor: Style.colors.yellow,
			borderWidth: 2,
		},
		today: {
			justifyContent: "space-between",
		},
		center: {
			alignSelf: "center",
		},
		weight: {
			borderRadius: Style.layout.paddingSlim,
			alignSelf: "center",
		},
		centerdata: {
			alignSelf: "center",
			padding: Style.layout.paddingContent,
			justifyContent: "center",
		},
		precedingdaysContainer: {
			flexDirection: "row",
			flexWrap: "wrap",
		},
		info: {
			position: "absolute",
			top: 0,
			right: 0,
			paddingHorizontal: Style.layout.marginSmall,
		},
		enternew: {
			borderRadius: Style.layout.paddingSlim,
			backgroundColor: Style.colors.secondary,
			borderColor: Style.colors.black,
			marginVertical: Style.layout.marginSmall,
			alignSelf: "center",
		},
		victoryGraphContainer: {
			flex: 1,
			justifyContent: "center",
		},

	},
	1024: {
		maincontainer: {
			flexDirection: "row",
			alignItems: "flex-start",
		},
		todaycontainer: {
			flexGrow: 1,
			justifyContent: "center",
			padding: Style.layout.paddingSlim,
			paddingLeft: 20,
			margin: Style.layout.marginSmall,
			marginBottom: Style.layout.marginMedium,
			flexDirection: "column",
			width: "30%",
		},
		padded: {
			alignSelf: "center",
		},
		multi: {
			flexDirection: "column",
			...Platform.select({
				ios: {
					alignItems: "flex-start",
				},
				android: {
					alignItems: "flex-start",
					maxWidth: "70%",
				},
			}),
		},
		fiveday: {
			flexDirection: "row",
		},
		precedingdaysContainer: {
			justifyContent: "space-around",
			flexWrap: "wrap",
			maxWidth: "70%",
			paddingLeft: 20,
		},
		multibuttons: {
			width: Style.layout.controlButtons,
			height: Style.layout.marginContentVertical,
		},
	},
});

var DEVICE_SECTIONS = [
	"activity",
	"bloodpressure",
	"bloodsugar",
	"bloodoxygen",
	"bodytemperature",
	"weight",
];

var SECTION = {
	"managedactivity": "activity",
	"managedbloodpressure": "bloodpressure",
	"managedbloodsugar": "bloodsugar",
	"managedbodytemperature": "bodytemperature",
	"managedbloodoxygen": "bloodoxygen",
	"managedweight": "weight",
};

export default function ManagedHealthIndicator(props) {
	const {
		localize,
		dispatch,
	} = useResources();
	var model = props.model;
	var _id = model.manage.account._id;
	var contact = model.manage.account;
	var renderReadingComment = model.manage.managedcomment.open;
	var group = model.activemanagegroup;
	var section = props.section;
	var xaxis = props.xaxis;
	var yaxis = props.yaxis;
	var bar = props.bar;
	var secondaryBar = props.secondaryBar === undefined ? bar : props.secondaryBar;
	var managed = props.managed;
	var activeModel = props.activeModel;
	const { weightUnits } = useMeasureUnits();
	const language = model.personal.language;

	var entries = activeModel.cumulative || [];
	var reverseEntries = props.reverseEntries || []; // needed for chart
	var short = activeModel.short || [];
	var secondaryData = props.secondaryGraph || [];
	var latestReading = props.latestReading;
	var latestSecondaryReading = props.latestSecondaryReading;
	var textformat = props.textformat;

	var units = props.units;
	var latestTitle = localize("glucose.latest") + " (" + units + ")";
	var trendTitle = short.length ? localize("glucose.trend") + " (" + units + ")" : null;
	var pageTitle = props.pagetitle;
	var nodata = localize("activity.nodata");
	var enter_new = localize("glucose.manual_entry");
	var openEdit = dispatch("newreading:trigger:show");
	var mysection = SECTION[section];

	var style_today = [styles.square, styles.latest];
	var currentWeight = model.manage.managedweight.latest ? localize("weight.title") + ": " + model.manage.managedweight.latest.history[weightUnits] + " " + weightUnits : localize("weight.title");
	var openWeight = dispatch("managedweight:trigger:view", {
		who: _id,
		group,
	});

	if (entries.length && entries[0].improved && section === "managedactivity" )
		style_today.push(styles.green);

	else if (entries.length && !entries[0].improved && section === "managedactivity")
		style_today.push(styles.red);

	else
		style_today.push(styles.green);


	var weightGraphic = section === "managedbloodpressure" ? (
		<StyledButton contrast emphasized style={styles.weight} title={currentWeight} onPress={openWeight} />
	) : null;

	var newentryGraphic =   (
		<StyledButton emphasized style={styles.enternew} contrast title={enter_new} onPress={() => openEdit(section)} />
	);

	var todayGraphic = null;
	var renderContentGraphic = null;

	var title = localize("myhealthdevice.read");

	var canRead = DEVICE_SECTIONS.indexOf(mysection) !== -1;
	var readFromDevice = null;

	if(canRead) {
		var onPress = dispatch("myhealthdevice:trigger:showinfo", {
			deviceType: mysection,
			who: _id,
		});

		readFromDevice = (
			<StyledButton onpress={onPress} contrast emphasized style={styles.enternew} title={title} onPress={onPress} />
		);
	}

	// Sets up the format to the 'today' button
	if (entries.length) {

		var date = section === "managedactivity" ? entries[entries.length - 1].latest_time : new Date(entries[0].updated_at);
		var time = time_parse(date, true);
		var created = created_date_label(time.time, time.month, time.day);
		var checkComment = section === "managedactivity" ? entries[entries.length - 1].comment : entries[0].history.comment;
		if(section === "managedbloodoxygen") checkComment = entries[0].history.comment || entries[0].history.exertion || entries[0].history.oxygen;
		var history = section === "managedactivity" ? entries[entries.length - 1] : entries[0].history;
		var showComment = dispatch("managedcomment:trigger:show", {
			data: history,
			type: section.replace("managed", ""),
		});
		var onpress = checkComment ? showComment : null;
		var info = checkComment ? (
			<StyledIcon style={styles.info} name="info" size={Style.text.standard} />
		) : null;

		var status = section === "managedactivity" ? entries[entries.length - 1].status : entries[0].history.status;
		if(section === "managedbloodoxygen"){
			var heartRateStatus = entries[0].history.heartRateStatus;
			var oxygenSaturationStatus = entries[0].history.oxygenSaturationStatus;
			status = heartRateStatus;
			if(oxygenSaturationStatus > heartRateStatus)
				status = oxygenSaturationStatus;
		}

		if(section === "managedbloodpressure"){
			var bpStatus = entries[0].history.status;
			var bpPulserateStatus = entries[0].history.bpPulserateStatus ?? -1;
			status = Math.max(bpStatus, bpPulserateStatus);
		}

		if(status === 0)
			style_today.push(styles.greenStatus);
		else if(status === 1)
			style_today.push(styles.yellow);
		else if(status === 2)
			style_today.push(styles.red);

		todayGraphic = (
			<View style={styles.todaycontainer}>
				<StyledText style={styles.padded} smallHeading>
					{latestTitle}
				</StyledText>
				<SquareButton
					title={created.time}
					secondaryTitle={created.date}
					text={latestReading}
					secondaryText={latestSecondaryReading}
					iconSize={Style.text.large}
					textSize={Style.text.smallHeading}
					style={style_today}
					clear
					info={info}
					onPress={onpress}
				>
					{info}
					<StyledText style={styles.padded} size={LONG_LANGS.includes(language) ? Style.text.standard : Style.text.smallHeading}>{parseStatus(status, localize)}</StyledText>
				</SquareButton>
				{weightGraphic}
				{newentryGraphic}
				{readFromDevice}
			</View>
		);
	}
	else
		todayGraphic = (
			<View style={styles.today}>
				<StyledText style={styles.padded} smallHeading>
					{latestTitle}
				</StyledText>
				<SquareButton
					title={" "}
					text={" "}
					iconSize={Style.text.large}
					textSize={Style.text.smallHeading}
					style={style_today}
					clear
				/>
				{weightGraphic}
				{newentryGraphic}
				{readFromDevice}
			</View>
		);


	var trendGraphic = (short.length) ? (
		<ManagedPreceedingMeasure short={short} section={section} textformat={textformat} language={language}/>
	) : null;

	var longTermGraphic = (entries.length > 5) ? (
		<View style={styles.victoryGraphContainer}>
			<VictoryGraph
				data={reverseEntries}
				xaxis={xaxis}
				yaxis={yaxis}
				bar={bar}
				managed={managed}
				trend={section === "managedbloodpressure"}
				padYLabel={section === "managedactivity" || section === "managedbloodoxygen"}
				xAxisLabel={localize("graphlabels." + section.replace("managed", "") + ".xaxis", {
					units: units,
				})}
				yAxisLabel={getYAxisLabel(section, pageTitle, units, localize)}
			/>
		</View>
	) : null;

	var longTermGraphic2 = section === "managedbloodoxygen" && (entries.length > 5) ? (
		<View style={styles.victoryGraphContainer}>
			<VictoryGraph
				data={secondaryData}
				xaxis={xaxis}
				yaxis={yaxis}
				bar={bar}
				managed={managed}
				xAxisLabel={localize("graphlabels.pulserate.xaxis")}
				yAxisLabel={localize("graphlabels.pulserate.yaxis")}
			/>
		</View>
	) : null;

	if(!longTermGraphic2 && secondaryData.length > 5)
		longTermGraphic2 = (<VictoryGraph
			data={secondaryData}
			xaxis={xaxis}
			yaxis={yaxis}
			bar={secondaryBar}
			managed={managed}
			xAxisLabel={props.secondaryGraphXLabel}
			yAxisLabel={props.secondaryGraphYLabel} />);


	var graphTitle = entries.length > 5 ? (
		<StyledText style={styles.padded} smallHeading>
			{getGraphTitle(section, pageTitle, units, localize)}
		</StyledText>
	) : null;

	var graphTitle2 = section === "managedbloodoxygen" && entries.length > 5 ? (
		<StyledText style={styles.padded} smallHeading>
			{localize("graphlabels.pulserate.title")}
		</StyledText>
	) : null;

	if(!graphTitle2 && secondaryData.length > 5)
		graphTitle2 = (<StyledText style={styles.padded} smallHeading>
			{props.secondaryGraphTitle}
		</StyledText>);


	var multidayContainer = entries.length ? (
		<View style={styles.multi} >
			<StyledText style={styles.padded} smallHeading>
				{trendTitle}
			</StyledText>
			<View style={styles.precedingdaysContainer}>
				{trendGraphic}
			</View>
			{graphTitle}
			{longTermGraphic}
			{graphTitle2}
			{longTermGraphic2}
		</View>
	) : (
		<View style={styles.centerdata} >
			<StyledText style={styles.center} smallHeading >{nodata}</StyledText>
		</View>
	);

	renderContentGraphic = (
		<View style={styles.maincontainer}>
			{todayGraphic}
			{multidayContainer}
		</View>
	);

	var renderComment = renderReadingComment ? (<ManagedComment model={model} />) : null;

	return (
		<>
			<MainScroll>
				<ManagedHeader title={pageTitle} contact={contact} />
				{renderContentGraphic}
			</MainScroll>
			{renderComment}
		</>
	);
}

function created_date_label(time, month, day) {
	return {
		time: time,
		date: month + "-" + day,
	};
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time,
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}

function getGraphTitle(section, pageTitle, units, localize) {
	if (section === "managedcustomindicator" || section === "customindicator")
		return localize("graphlabels." + section.replace("managed", "") + ".title", {
			customIndicatorName: pageTitle,
		});
	else
		return localize("graphlabels." + section.replace("managed", "") + ".title", {
			units: units,
		});

}

function getYAxisLabel(section, pageTitle, units, localize) {
	if (section === "managedcustomindicator" || section === "customindicator")
		return localize("graphlabels." + section.replace("managed", "") + ".yaxis", {
			customIndicatorName: pageTitle,
			units: units,
		});
	else
		return localize("graphlabels." + section.replace("managed", "") + ".yaxis", {
			units: units,
		});

}
