/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useState } from "react";
import {
	View,
	StyleSheet,
	Platform,
} from "react-native";

import CardPage from "../../Layout/CardPage";
import Form from "ae-forms";
import SearchSchema from "../../../schemas/Search";
import StyledText from "ae-base/Base/StyledText";
import ToRequest from "../../Contacts/ToRequest";
import MessagingInput from "../Messaging/MessagingInput/MessagingInput";
import StyledButton from "ae-base/Base/StyledButton";
import Heading from "ae-base/Base/Heading";
import Link from "ae-base/Base/Link";
import FileUploadDialog from "../Messaging/FileUploadDialog";

import Style from "ae-style";

import { useResources } from "@aetonix/hooks";

var styles = StyleSheet.create({
	searchText: {
		marginVertical: Style.layout.marginLarge,
	},
	searchBox: {
		marginBottom: Style.layout.marginLarge,
	},
});

export default function MassMessage(props) {
	const {
		localize,
		dispatch,
		config,
	} = useResources();

	const [seletedContacts, setSeletedContacts] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [file, setFile] = useState(null);
	const [numberOfUsersListed, setNumberOfUsersListed] = useState(5);
	const searchcontent = localize("bulkMessaging.bulkSearchDescription");
	const noresult = localize("request.noresult");
	const gobackcontact = localize("request.gobackcontact");
	const addBulkMessaging = localize("bulkMessaging.bulkMessaging");
	let ownID = config.id;
	let model = props.model;
	let screen = model.size;
	let messages = model.messages;
	let uploading = messages.uploading;
	let texting = {
		texting: messages.texting || "",
	};
	var doSearch = dispatch("search:trigger:search");

	var search = model.search;
	var contacts = model.contacts.contacts;
	contacts = contacts.concat(model.contacts.merged.filter((contact) => contact.isContact));
	var searchResults = search.search || [];

	let selectAllContacts = () => {
		let selected = !selectAll;

		if(selected)
			setSeletedContacts(contacts.sort((a, b) => a?.lname?.localeCompare(b?.lname)).map((contact => contact._id)));

		else
			setSeletedContacts([]);

		setSelectAll(selected);
	};

	let updateStatus = (newContacts) => {
		if(contacts.length === newContacts.length)
			setSelectAll(true);

		if(contacts.length !== newContacts.length)
			setSelectAll(false);
	};

	const contactItems = contacts.sort((a, b) => a?.lname?.localeCompare(b?.lname))
		.map((element, i) =>{

			if(i > numberOfUsersListed)
				return;

			const id = element._id;
			let message = localize("common.add");
			let icon = "plus";

			let action = () => {
				let newSeletedContacts = [...seletedContacts, id];
				setSeletedContacts(newSeletedContacts);
				dispatch("usermetrics:trigger:track", "add contact to bulk messaging", {id})();
				updateStatus(newSeletedContacts);
			};

			let removeAction = () => {
				const newSeletedContacts = seletedContacts.filter(addedUser => addedUser !== id);
				setSeletedContacts(newSeletedContacts);
				dispatch("usermetrics:trigger:track", "remove contact from bulk messaging", {id})();
				updateStatus(newSeletedContacts);
			};

			if(seletedContacts && seletedContacts.indexOf(id) !== -1){
				action = removeAction;
				message = localize("common.remove");
				icon = "user";
			}

			return (
				<ToRequest
					key={id}
					who={element}
					action={action}
					icon={icon}
					message={message}
					screen={screen}
				/>
			);
		});

	const loadMore = () => {
		if((numberOfUsersListed + 10) < contacts.length)
			setNumberOfUsersListed(numberOfUsersListed + 10);
		else
			setNumberOfUsersListed(contacts.length - 1);
	};

	const renderedResults = searchResults.filter(function(result){
		return result._id !== ownID;
	})
		.filter((result) => {
			let match = false;

			contacts.forEach((contact) => {
				if(result._id === contact._id)
					match = true;
			});

			return match;
		})
		.map(function(result){
			var id = result._id;
			var message = localize("common.add");
			var icon = "plus";

			let action = () => {
				let newSeletedContacts = [...seletedContacts, id];
				setSeletedContacts(newSeletedContacts);
				dispatch("usermetrics:trigger:track", "add contact to bulk messaging from search results", {id})();
				updateStatus(newSeletedContacts);
			};

			let removeAction = () => {
				let newSeletedContacts = seletedContacts.filter(addedUser => addedUser !== id);
				setSeletedContacts(newSeletedContacts);
				dispatch("usermetrics:trigger:track", "remove contact for bulk messaging from search results", {id})();
				updateStatus(newSeletedContacts);
			};

			if(seletedContacts && seletedContacts.indexOf(id) !== -1){
				action = removeAction;
				message = localize("common.remove");
				icon = "user";
			}

			return (
				<ToRequest
					key={id}
					who={result}
					action={action}
					icon={icon}
					message={message}
					screen={screen}
				/>
			);
		});

	var searchForm = (
		<View>
			<StyledText style={styles.searchText}>{searchcontent}</StyledText>
			<View style={styles.searchBox}>
				<Form schema={SearchSchema} defaultData={search} autoSubmit onSubmit={doSearch} textStyle={{"fontSize": Style.text.standard}} />
			</View>
		</View>
	);

	var noResults = search.query && !searchResults.length;
	var defaultMessage = messages?.defaultMessage;

	var notFound = (noResults &&  !search.isEmail) ? (
		<StyledText>{noresult}</StyledText>
	) : null;

	const startUpload = dispatch("messages:trigger:upload:start");

	// eslint-disable-next-line
    const onUpload = function(uploadFile){
		if(Platform.OS === "web")
			uploadFile.url = URL.createObjectURL(uploadFile);

		setFile(uploadFile);
		dispatch("messages:trigger:upload:confirm")({file: uploadFile});
	};

	const closeUploadDialog = dispatch("messages:trigger:upload:close");

	const cancelUpload = () => {
		setFile(null);
		dispatch("messages:trigger:upload:cancel")();
	};

	const sendMessage = (content) => {
		dispatch("messages:trigger:bulk:send", {
			message: content,
			who: seletedContacts,
		})();

		setFile(null);
	};

	var renderDialog = null;

	if(uploading)
		renderDialog = (<FileUploadDialog onUpload={onUpload} closeDialog={closeUploadDialog}
			cancelUpload={cancelUpload} title={localize("bulkMessaging.uploadFile")} file={file}/>);

	return (
		<CardPage backTitle={gobackcontact} title={addBulkMessaging} toast={model.toast} icon="contacts" screen={screen} online={model.online}>
			{searchForm}
			{notFound}
			{renderedResults}
			<Heading title={localize("bulkMessaging.composeMessage")} />
			{file && Platform.OS === "web" ? <Link title={file.name} uri={file.url} /> :  null}
			{file && Platform.OS !== "web" ? <StyledText id={file.name + "_text"} bold>{file.name}</StyledText> :  null}
			<MessagingInput startUpload={startUpload} messages={texting} name={""} sendMessage={sendMessage} noMargin type="bulk"/>
			<Heading title={localize("bulkMessaging.contacts")} />
			<StyledButton clear title={selectAll ? localize("bulkMessaging.unselectAll") : localize("bulkMessaging.selectAll")} onPress={selectAllContacts} />
			{contactItems}
			{numberOfUsersListed < (contacts.length - 1) ?
				<StyledButton clear title={localize("bulkMessaging.loadMore")} onPress={loadMore} style={{
					"marginLeft": "auto",
					"marginRight": "auto",
					"marginTop": "2%",
					"marginBottom": "2%",
				}}/> : null}
			{renderDialog}
		</CardPage>
	);
}
