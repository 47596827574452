import React from "react";
import { View } from "react-native";
import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import SquareButton from "ae-base/Base/SquareButton";
import StyledButton from "ae-base/Base/StyledButton";
import HealthHeader from "../HealthHeader";
import MainScroll from "../../../Layout/MainScroll";
import VictoryGraph from "../../../HealthIndicators/VictoryGraph";
import PreceedingMeasure from "../../../aTouchAway/ATouchAwayMyHealth/PreceedingMeasure";
import Comment from "./Comment";
import { useMeasureUnits } from "@aetonix/hooks";
import { parseStatus } from "../../../../utils/ParseStatus";

import { useResources } from "@aetonix/hooks";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		todaycontainer: {
			flexGrow: 1,
			justifyContent: "center",
			padding: Style.layout.paddingSlim,
			margin: Style.layout.marginSmall,
			marginBottom: Style.layout.marginMedium,
			flexDirection: "column",
		},
		dateLabel: {
			top: -6,
		},
		maincontainer: {
			flexDirection: "column",
		},
		row: {
			flexDirection: "row",
		},
		latest: {
			margin: Style.layout.marginSlim,
			width: Style.imageSize.quitesmall,
			height: Style.imageSize.quitesmall,
			justifyContent: "center",
			alignSelf: "center",
			borderColor: Style.colors.primary,
		},
		square: {
			borderStyle: "solid",
			borderRadius: Style.layout.marginSmall,
			borderWidth: Style.layout.marginSlim,
			backgroundColor: "white",
			alignSelf: "center",
			borderColor: Style.colors.primary,
			paddingVertical: Style.layout.paddingSmall,
		},
		multibuttons: {
			width: Style.layout.paddingContent,
			height: Style.layout.paddingContent,
		},
		padded: {
			alignSelf: "center",
			whiteSpace: "nowrap",
		},
		multi: {
			flexGrow: 2,
			alignItems: "center",
			flexDirection: "column",
		},
		fiveday: {
			flexDirection: "row",
			marginBottom: Style.layout.marginLarge,
		},
		green: {
			borderColor: Style.colors.primary,
		},
		today: {
			justifyContent: "space-around",
		},
		center: {
			alignSelf: "center",
		},
		weight: {
			borderRadius: Style.layout.paddingSlim,
			alignSelf: "center",
			marginBottom: Style.layout.marginSmall,
			marginTop: Style.layout.marginSmall,
			backgroundColor: Style.colors.dark,
		},
		centerdata: {
			alignSelf: "center",
			padding: Style.layout.paddingContent,
			justifyContent: "center",
		},
		enternew: {
			borderRadius: Style.layout.paddingSlim,
			backgroundColor: Style.colors.secondary,
			borderColor: Style.colors.black,
			marginVertical: Style.layout.marginSmall,
			alignSelf: "center",
		},
		precedingdaysContainer: {
			flexDirection: "row",
			flexWrap: "wrap",
		},
		info: {
			position: "absolute",
			top: 0,
			right: 0,
			paddingHorizontal: Style.layout.marginSmall,
		},
		red: {
			borderColor: Style.colors.alert,
			borderWidth: 2,
		},
		greenStatus: {
			borderColor: Style.colors.green,
			borderWidth: 2,
		},
		yellow: {
			borderColor: Style.colors.yellow,
			borderWidth: 2,
		},
		victoryGraphContainer: {
			flex: 1,
			justifyContent: "center",
		},
	},
	500: {
		multi: {
			flexDirection: "column",
		},
		fiveday: {
			flexDirection: "row",
		},
		precedingdaysContainer: {
			justifyContent: "space-around",
			flexWrap: "wrap",
		},
		multibuttons: {
			width: Style.layout.controlButtons,
			height: Style.layout.marginContentVertical,
		},
		padded: {
			alignSelf: "center",
			whiteSpace: "nowrap",
		},
	},
});

var SECTION = {
	myactivity: "activity",
	mybloodpressure: "bloodpressure",
	mybloodsugar: "bloodsugar",
	mybodytemperature: "bodytemperature",
	mybloodoxygen: "bloodoxygen",
	myweight: "weight",
	mycustomindicator: "customindicator",
};

var DEVICE_SECTIONS = ["activity", "bloodpressure", "bloodsugar", "bloodoxygen", "bodytemperature", "weight"];

export default function HealthIndicator(props) {
	const {
		localize,
		dispatch,
	} = useResources();

	var model = props.model;
	var personal = model.personal;
	var _id = personal._id;
	var myhealth = model.myhealth;
	var renderReadingComment = myhealth.comment.open;
	var section = props.section;
	var xaxis = props.xaxis;
	var yaxis = props.yaxis;
	var bar = props.bar;
	var secondaryBar = props.secondaryBar === undefined ? bar : props.secondaryBar;
	var managed = props.managed;
	var activeModel = props.activeModel;

	const { weightUnits } = useMeasureUnits();

	var entries = activeModel.cumulative || [];
	var reverseEntries = props.reverseEntries || []; // needed for chart
	var short = activeModel.short || [];
	var secondaryData = props.secondaryGraph || [];
	var latestReading = props.latestReading;
	var latestSecondaryReading = props.latestSecondaryReading;
	var textformat = props.textformat;
	var units = props.units;
	var latestTitle = localize("glucose.latest") + " (" + units + ")";
	var trendTitle = short.length ? localize("glucose.trend") + " (" + units + ")" : null;
	var pageTitle = props.pagetitle;
	var nodata = localize("activity.nodata");
	var openEdit = dispatch("newreading:trigger:show");
	var enter_new = localize("glucose.manual_entry");
	var mysection = SECTION[section];
	var feedback = myhealth.feedbackmodal;
	var feedbackmodel = model.myhealth;

	var atouchawaysettings = model.atouchawaysettings || {};
	var myhealth_sections = atouchawaysettings.myhealth_sections || {};

	var style_today = [styles.square, styles.latest];
	var currentWeight = myhealth.weight.latest
		? localize("weight.title") + ": " + myhealth.weight.latest.history[weightUnits] + " " + weightUnits
		: localize("weight.title");
	var openWeight = dispatch("weight:trigger:view", {
		who: _id,
	});

	if (entries.length && entries[0].improved && section === "myactivity")
		style_today.push(styles.green);
	 else if (entries.length && !entries[0].improved && section === "myactivity")
		style_today.push(styles.red);
	 else
		style_today.push(styles.green);


	var weightGraphic =
		section === "mybloodpressure" ? (
			<StyledButton contrast emphasized style={styles.weight} title={currentWeight} onPress={openWeight} />
		) : null;

	let hideManualReading = false;
	if (section === "mycustomindicator" || section === "customindicator") {
		if (`${mysection}s_manualentry` in myhealth_sections)
			hideManualReading = myhealth_sections[mysection + "s_manualentry"];

	} else
		if (`${mysection}_manualentry` in myhealth_sections)
			hideManualReading = myhealth_sections[mysection + "_manualentry"];



	var newentryGraphic = hideManualReading ? null : (
		<StyledButton emphasized style={styles.enternew} contrast title={enter_new} onPress={() => openEdit(section)} />
	);

	var todayGraphic = null;
	var renderContentGraphic = null;

	var title = localize("myhealthdevice.read");

	var hideReadFromDevice = myhealth_sections[mysection + "_readdevice"];
	var canRead = DEVICE_SECTIONS.indexOf(mysection) !== -1;
	var readFromDevice = null;

	if (canRead) {
		var onPress = dispatch("myhealthdevice:trigger:showinfo", {
			deviceType: mysection,
		});

		readFromDevice = hideReadFromDevice ? null : (
			<StyledButton onpress={onPress} contrast emphasized style={styles.enternew} title={title} onPress={onPress} />
		);
	}

	// Sets up the format to the 'today' button
	if (entries.length) {
		var date = section === "myactivity" ? entries[entries.length - 1].created_at : new Date(entries[0].updated_at);
		var time = time_parse(date, true);
		var created = created_date_label(time.time, time.month, time.day);
		var checkComment = section === "myactivity" ? entries[entries.length - 1].comment : entries[0].history.comment;
		var history = section === "myactivity" ? entries[entries.length - 1] : entries[0].history;

		var showComment = dispatch("comment:trigger:show", {
			type: section.replace("my", ""),
			data: history,
		});
		var onpress = checkComment ? showComment : null;
		var info = checkComment ? <StyledIcon style={styles.info} name="info" size={Style.text.standard} /> : null;

		var status = section === "myactivity" ? entries[entries.length - 1].status : entries[0].history.status;
		if (section === "mybloodoxygen") {
			var heartRateStatus = entries[0].history.heartRateStatus;
			var oxygenSaturationStatus = entries[0].history.oxygenSaturationStatus;
			status = heartRateStatus;
			if (oxygenSaturationStatus > heartRateStatus) status = oxygenSaturationStatus;
		}
		if(section === "mybloodpressure"){
			var bpStatus = entries[0].history.status;
			var bpPulserateStatus = entries[0].history.bpPulserateStatus || 0;
			status = Math.max(bpStatus, bpPulserateStatus);
		}

		if (status === 0) style_today.push(styles.greenStatus);
		else if (status === 1) style_today.push(styles.yellow);
		else if (status === 2) style_today.push(styles.red);

		todayGraphic = (
			<View style={styles.todaycontainer}>
				<StyledText style={styles.padded} smallHeading>
					{latestTitle}
				</StyledText>
				<SquareButton
					title={created.time}
					secondaryTitle={created.date}
					text={latestReading}
					secondaryText={latestSecondaryReading}
					iconSize={Style.text.large}
					textSize={Style.text.smallHeading}
					style={style_today}
					clear
					info={info}
					onPress={onpress}
				>
					{info}
					<StyledText style={styles.padded} smallHeading>{parseStatus(status, localize)}</StyledText>
				</SquareButton>
				{weightGraphic}
				{newentryGraphic}
				{readFromDevice}
			</View>
		);
	} else
		todayGraphic = (
			<View style={styles.today}>
				<StyledText style={styles.padded} smallHeading>
					{latestTitle}
				</StyledText>
				<SquareButton
					title={" "}
					text={" "}
					iconSize={Style.text.large}
					textSize={Style.text.smallHeading}
					style={style_today}
					clear
				/>
				{weightGraphic}
				{newentryGraphic}
				{readFromDevice}
			</View>
		);


	var trendGraphic = short.length ? (
		<PreceedingMeasure short={short} section={mysection} textformat={textformat} />
	) : null;

	var longTermGraphic =
		entries.length > 5 ? (
			<View style={styles.VictoryGraphContainer}>
				<VictoryGraph
					data={reverseEntries}
					xaxis={xaxis}
					yaxis={yaxis}
					bar={bar}
					managed={managed}
					trend={section === "mybloodpressure"}
					padYLabel={section === "myactivity" || section === "mybloodoxygen"}
					xAxisLabel={localize("graphlabels." + section.replace("my", "") + ".xaxis", {
						units: units,
					})}
					yAxisLabel={getYAxisLabel(section, pageTitle, units, localize)}
				/>
			</View>
		) : null;

	var graphTitle =
		entries.length > 5 ? (
			<StyledText style={styles.padded} smallHeading>
				{getGraphTitle(section, pageTitle, units, localize)}
			</StyledText>
		) : null;

	var longTermGraphic2 =
		section === "mybloodoxygen" && entries.length > 5 ? (
			<View style={styles.VictoryGraphContainer}>
				<VictoryGraph
					data={secondaryData}
					xaxis={xaxis}
					yaxis={yaxis}
					bar={bar}
					managed={managed}
					xAxisLabel={localize("graphlabels.pulserate.xaxis")}
					yAxisLabel={localize("graphlabels.pulserate.yaxis")}
				/>
			</View>
		) : null;

	if (!longTermGraphic2 && secondaryData.length > 5)
		longTermGraphic2 = (
			<View style={styles.VictoryGraphContainer}>
				<VictoryGraph
					data={secondaryData}
					xaxis={xaxis}
					yaxis={yaxis}
					bar={secondaryBar}
					managed={managed}
					xAxisLabel={props.secondaryGraphXLabel}
					yAxisLabel={props.secondaryGraphYLabel}
				/>
			</View>
		);


	var graphTitle2 =
		section === "mybloodoxygen" && entries.length > 5 ? (
			<StyledText style={styles.padded} smallHeading>
				{localize("graphlabels.pulserate.title")}
			</StyledText>
		) : null;

	if(!graphTitle2 && secondaryData.length > 5)
		graphTitle2 = (<StyledText style={styles.padded} smallHeading>
			{props.secondaryGraphTitle}
		</StyledText>);


	var multidayContainer = entries.length ? (
		<View style={styles.multi}>
			<StyledText style={styles.padded} smallHeading>
				{trendTitle}
			</StyledText>
			<View style={styles.precedingdaysContainer}>{trendGraphic}</View>
			{graphTitle}
			{longTermGraphic}
			{graphTitle2}
			{longTermGraphic2}
		</View>
	) : (
		<View style={styles.centerdata}>
			<StyledText style={styles.center} smallHeading>
				{nodata}
			</StyledText>
		</View>
	);

	renderContentGraphic = (
		<View style={styles.maincontainer}>
			{todayGraphic}
			{multidayContainer}
		</View>
	);

	if (renderReadingComment)
		return <Comment model={model.myhealth} />;


	return (
		<MainScroll>
			<HealthHeader title={pageTitle} />
			{renderContentGraphic}
		</MainScroll>
	);
}

function created_date_label(time, month, day) {
	return {
		time: time,
		date: month + "-" + day,
	};
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time,
	};
}

function two_digit(data) {
	var item = data < 10 ? "0" + data : data;
	return item;
}

function getGraphTitle(section, pageTitle, units, localize) {
	if (section === "mycustomindicator" || section === "customindicator")
		return localize("graphlabels." + section.replace("my", "") + ".title", {
			customIndicatorName: pageTitle,
		});
	else
		return localize("graphlabels." + section.replace("my", "") + ".title", {
			units: units,
		});

}

function getYAxisLabel(section, pageTitle, units, localize) {
	if (section === "mycustomindicator" || section === "customindicator")
		return localize("graphlabels." + section.replace("my", "") + ".yaxis", {
			customIndicatorName: pageTitle,
			units: units,
		});
	else
		return localize("graphlabels." + section.replace("my", "") + ".yaxis", {
			units: units,
		});

}
